import React from 'react';
import ParticleBackground from './BackgroungComponent';
import { Button, Card, CardText } from 'reactstrap';
import { FadeTransform } from 'react-animation-components';
import { Link } from 'react-router-dom';

function About() {
    return(
        <div>
        <div className="page-data ">
          <div>
            <ParticleBackground />
          
          </div>
          <div className="col-6 offset-3">
          <div className="aboutPage">
          <FadeTransform
            in
            transfromProps={{
              exitTransform: 'scale(0.9) translateY(90%)'
            }}>  
            <Card id="aboutCard">
                
                <CardText>
                    <div className="container">
                      <h3 className="aboutTitle">About Me</h3>
                      <p>A Full-Stack Web Developer.</p>
                      <p>I'm Adam Tyrrell, an aspiring Web Developer. I enjoy solving problems by using the web and it's abundant resouces. I recently graduated from a font-end coding bootcamp in July 2021, and have started applying what I've learned in my Projects.</p>
                      <p>In my course work and freelance work I have become cofortable with many technologies including HTML, CSS, Bootstrap, JavaScript, React, ReactNative, Node, MongoDB and Wordpress. </p>
                      <p>I am currently finishing up a back-end bootcamp and am excited to tie everything all together with my own code. Feel free to check out my current projects and resume!</p>
                      <div className="text-center mb-4">
                          <Button outline color="secondary" className="col-md-3 mr-2 mb-2" tag={Link} to="/projects">Projects </Button>
                          <Button outline color="secondary" className="col-md-3 mb-2" onClick={(e) => window.open('http://adamtyrrell.com/AdamTyrrell.pdf','_blank')}> Resume </Button>
                      </div>
                    </div>
                </CardText>
                
            </Card>
            </FadeTransform>
            </div>
          </div>
        </div>
        </div>
        
    );

}


export default About;
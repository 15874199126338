import React, { Component } from 'react';
import ParticleBackground from './BackgroungComponent';
import { Button, Card, CardText,Form, FormGroup, Label, Input, Col } from 'reactstrap';
import { FadeTransform } from 'react-animation-components';


class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            senderName: '',
            email: '',
            message: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value =  target.value
    
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        console.log('Current state is: ' + JSON.stringify(this.state));
        alert('Message Sent! ' + JSON.stringify(this.state));
        event.preventDefault();
    }

    render(){
    return(
        <div>
        <div className="page-data ">
          <div>
            <ParticleBackground />
          
          </div>
          <div className="col-lg-6 offset-lg-3">
          <div className="aboutPage">
          <FadeTransform
            in
            transfromProps={{
              exitTransform: 'scale(0.9) translateY(90%)'
            }}>  
            <Card id="aboutCard">
                
                <CardText>
                    <div className="container">
                    <h3 className="aboutTitle text-center">Contact Me</h3>
                    <p className="text-center">Let's talk.</p>
                    <Form onSubmit={this.handleSubmit}>
                            <FormGroup row>
                                <Label htmlFor="senderName" md={2}>Name</Label>
                                <Col md={10}>
                                    <Input type="text" id="senderName" name="senderName"
                                        placeholder="Name"
                                        value={this.state.senderName}
                                        onChange={this.handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label htmlFor="email" md={2}>Email</Label>
                                <Col md={10}>
                                    <Input type="email" id="email" name="email"
                                        placeholder="Email"
                                        value={this.state.email}
                                        onChange={this.handleInputChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label htmlFor="message" md={2}>Message</Label>
                                <Col md={10}>
                                    <Input type="textarea" id="message" name="message"
                                        rows="12"
                                        value={this.state.message}
                                        onChange={this.handleInputChange}></Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md={{size: 10, offset: 2}}>
                                    <Button type="submit" color="primary">
                                        Send Message
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                    
                </CardText>
                
            </Card>
            </FadeTransform>
            </div>
          </div>
        </div>
        </div>
        
    );
    }

}

export default Contact;
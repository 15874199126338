import React from 'react';
import Particles from 'react-tsparticles';
import { ParticleConfig } from './particle-config';

export default function ParticleBackground() {
    return (
        <div className="bg-height">
        <Particles params={ParticleConfig}></Particles>
        /</div>
    );
}
import React, { Component } from 'react';
import Home from './HomeComponent';
import About from './AboutComponent';
import Contact from './ContactComponent';
import Projects from './ProjectsComponent';
import Header from './HeaderComponent';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';


class Main extends Component {

    render() {

        const HomePage = () => {
            return (
                <Home />
            );
        };

        return (
            <div>
                <Header />
                    <Switch>
                        <Route path='/home' component={HomePage} />
                        <Route path='/about' component={About} />
                        <Route path='/contact' component={Contact} />
                        <Route path='/projects' component={Projects}/>
                        <Redirect to='/home' />
                    </Switch>
            </div>
        );
    
    }
}

export default Main;
import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './components/MainComponent';
import { BrowserRouter } from 'react-router-dom';




function App() {
    return (
      <BrowserRouter>
      <div className="App">
          <Main />
      </div>
  </BrowserRouter>

    );
  }




export default App;

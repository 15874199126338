import React from 'react';
import ParticleBackground from './BackgroungComponent';
import { Button } from 'reactstrap';
import { FadeTransform } from 'react-animation-components';
import {Link} from 'react-router-dom';
import 'bootstrap-social/bootstrap-social.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
    return (
      <div>
        <div className="page-data ">
          <div>
            <ParticleBackground />
          </div>
          <FadeTransform
            in
            transfromProps={{
              exitTransform: 'scale(0.9) translateY(90%)'
            }}>            
          <div className="container offset-lg-3 offset-xs-1 mt-5">
            <div className="row">
              <div className="col">
                <div className="homePage"></div>  
                <div className=" nameTag center_all">
                  <div className="  offset-2 CenterTile" >          
                    <CenterTile/>
                  </div>
                  <div className="  offset-2 subTile">
                    <SubTile />
                  </div>
                  <div className=" offset-2 subTile button-div">
                    <Button outline color="warning" tag={Link} to="/projects"> My Projects</Button>
                  </div>

                </div> 
              </div>
            </div>       
          </div>
          </FadeTransform>
        </div>
        
      </div>

    );
  }

function CenterTile() {
  return (

      <p className="h1-name">Adam Tyrrell</p>    
  );
}
function SubTile() {
  return (

      <p> A Full-Stack Web Developer.</p>    
  );
}

export default Home;
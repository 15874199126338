import React from 'react';
import ParticleBackground from './BackgroungComponent';
import { Button, Card, CardText } from 'reactstrap';
import { FadeTransform } from 'react-animation-components';
import ticketbook from '../images/ticketbookscreen.png';
import tim from '../images/tim.png';
import lotto from '../images/lotto.png';
import hulu from  '../images/huluscreen.png';

function Projects() {
    return(
        <div>
        <div className="page-data ">
          <div>
            <ParticleBackground />
          
          </div>
          <div className="col-md-6 offset-md-3 ">
          <div className="aboutPage">
          <FadeTransform
            in
            transfromProps={{
              exitTransform: 'scale(0.9) translateY(90%)'
            }}>
                <div className="projectTitle mb-3">
                    <h1>Projects</h1>
                </div>  
                <Card className="mb-3">               
                <CardText>
                    <div className="container">
                        <h3 className="aboutTitle">Hulu Clone</h3>
                        <p>Project Summary:</p>
                        <div className="row">
                            <div className="col-lg-7">
                                <p>The scope of this project to was to replicate the front end of popular video streaming platform Hulu. This was accomplished by using NEXT.JS , Tailwind.CSS and the use of API calls to the TMDB database. It features a fully responsive design, subtle animations,  and lazy loading for images. </p>
                                <div className="text-center">
                                    <Button outline color="secondary" className="col-lg-3 mr-2 mb-2"onClick={(e) => window.open('http://video.adamtyrrell.com','_blank')}>Demo </Button>
                                    <Button outline color="secondary" className="col-lg-3 mb-2"onClick={(e) => window.open('https://github.com/adamtyrrell/huluclone','_blank')}> Code </Button>
                                </div>
                            </div>
                            <div className="col-lg-5 mb-4">
                                <img src={hulu} width="100%"alt="ticketbook" />
                            </div>
                        </div>
                    </div>
                </CardText>               
            </Card>

            <Card id="projectCard1 mt-3">               
                <CardText>
                    <div className="container">
                        <h3 className="aboutTitle">TicketBook</h3>
                        <p>Project Summary:</p>
                        <div className="row">
                            <div className="col-lg-7">
                                <p>With a background in law enforcement, I found many needs for practical software for the job during my career. One of which was a need for a tool to process citation writing for in-house tickets and reports for the jail. As a training officer it was my responsibility to teach new officers how to write tickets. At the department I worked for tickets were hand written. This made for many frustrating rewrites for every time an officer would make a mistake on the citation. Additionally, there was no system in place to review, track or search previously written reports or citations. This project is my approach to remedy the problem with a web application.</p>
                                <div className="text-center">
                                    <Button outline color="secondary" className="col-lg-3 mr-2 mb-2"onClick={(e) => window.open('http://ticketbook.site','_blank')}>Demo </Button>
                                    <Button outline color="secondary" className="col-lg-3 mb-2"onClick={(e) => window.open('https://github.com/adamtyrrell/ticketbookreact','_blank')}> Code </Button>
                                </div>
                            </div>
                            <div className="col-lg-5 mb-4">
                                <img src={ticketbook} width="100%"alt="ticketbook" />
                                <p>Login is: username: demo password: demo</p>
                            </div>
                        </div>
                    </div>
                </CardText>               
            </Card>

            <div className="mt-3">
                <Card>               
                    <CardText>
                        <div className="container">
                            <h3 className="aboutTitle">Broadcaster Portfolio</h3>
                            <p>Project Summary:</p>
                            <div className="row">
                                <div className="col-lg-7">
                                    <p>A friend of mine is a sports broadcaster. He reached out to me asking for help with his website that he was attempting to make with one of the highly commercialized DIY web design platforms. He couldn’t get it set up the way he wanted and he was stuck with a website that looked like it was from the 90’s. </p>
                                    <p>He wanted something simple to display his work and contact information. I use Bootstrap for the pages and handled the domain/hosting setup.</p>
                                    <div className="text-center">
                                        <Button outline color="secondary" className="col-lg-3 mr-2 mb-2" onClick={(e) => window.open('http://timspoerl.com','_blank')}>Demo </Button>
                                        <Button outline color="secondary" className="col-lg-3 mb-2" onClick={(e) => window.open('https://github.com/adamtyrrell/tim','_blank')}> Code </Button>
                                    </div>
                                </div>
                                <div className="col-lg-5 mb-4">
                                    <img src={tim} width="100%"alt="tim" />
                                </div>
                            </div>
                        </div>
                    </CardText>                 
                </Card>
            </div>

            <div className="mt-3 mb-4">
            <Card>               
                <CardText id="projectCard2" >
                <div className="container">
                    <h3 className="aboutTitle">Lottery Number Generator</h3>
                        <p>Project Summary:</p>
                        <div className="row">
                            <div className="col-lg-7">
                                <p>This project was set up to be fun and learn more with JavaScript. It is a tool that will select randomized lottery numbers. I made it with JavaScript, HTML, & CSS. Every time a user clicks submit a new set of numbers is generated. Whilst there is no guarantee that the program will select winning numbers, it will allow you to explore randomized numbers that have just as good of a chance as any other numbers. </p>
                                <div className="text-center">
                                    <Button outline color="secondary" className="col-lg-3 mr-2 mb-2" onClick={(e) => window.open('http://adamtyrrell.com/lotto/index.html','_blank')}>Demo </Button>
                                    <Button outline color="secondary" className="col-lg-3 mb-2" onClick={(e) => window.open('https://github.com/adamtyrrell/luckylotto','_blank')}> Code </Button>
                                </div>
                            </div>
                            <div className="col-lg-5 mb-4">
                                <img src={lotto} width="100%"alt="ticketbook" />
                            </div>
                        </div>
                    </div>
                </CardText>               
            </Card>
            </div>
            </FadeTransform>
            </div>
          </div>
        </div>
        </div>
        
    );

}


export default Projects;